import { Grid } from '@mui/material';
import { RoutalLoader, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';

import RatingStar from '../assets/svg/RatingStar';
import BadCondition from '../assets/svg/survey-rating-tags/BadCondition';
import BadDriver from '../assets/svg/survey-rating-tags/BadDriver';
import BadInfo from '../assets/svg/survey-rating-tags/BadInfo';
import BadTime from '../assets/svg/survey-rating-tags/BadTime';
import GoodCondition from '../assets/svg/survey-rating-tags/GoodCondition';
import GoodDriver from '../assets/svg/survey-rating-tags/GoodDriver';
import GoodInfo from '../assets/svg/survey-rating-tags/GoodInfo';
import GoodTime from '../assets/svg/survey-rating-tags/GoodTime';
import { useStopContext } from '../context/stop/context';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { SurveyRatingTags } from '../domain/survey/SurveyRatingTags';
import { intl } from '../helpers/IntGlobalProvider';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useCreateSurvey } from '../hooks/survey/useCreateSurvey';
import { useUpdateSurvey } from '../hooks/survey/useUpdateSurvey';
import { Button, ButtonProps } from '../react-components/Button';
import { InputText } from '../react-components/InputText';
import { Text } from '../react-components/Text';

const getStopSurveyRatingTagText = ({
  rating = 0,
  ratingTag,
}: {
  rating?: number;
  ratingTag?: SurveyRatingTags;
}): string => {
  const isBad = rating <= 2;
  return ratingTag ? `customers.survey.rating_tag.${isBad ? `bad` : `good`}.${ratingTag}` : ``;
};

const SurveySubmitButton = (
  props: React.PropsWithChildren<ButtonProps> &
    React.HTMLAttributes<ButtonProps> &
    React.ButtonHTMLAttributes<ButtonProps>
) => {
  return (
    <Button
      ripple
      style={{
        height: `32px`,
        width: `100%`,
        maxWidth: `150px`,
        fontSize: `14px`,
        fontWeight: `normal`,
      }}
      {...props}
    />
  );
};

const ratingTags = [`time`, `condition`, `driver`, `info`] as SurveyRatingTags[];

interface SurveyData {
  rating?: number;
  rating_tag?: SurveyRatingTags;
  comments?: string;
}

const defaultSurveyData = {
  rating: undefined,
  rating_tag: undefined,
  comments: undefined,
} as SurveyData;

interface CustomersSurveyProps {
  stop: CustomersStop;
}

export const CustomersSurvey = ({ stop }: CustomersSurveyProps) => {
  const { data: project } = useRetrieveProject();
  const { stopState } = useStopContext();
  const [step, setStep] = useState<number>(0);
  const [surveyData, setSurveyData] = useState<SurveyData>(defaultSurveyData);

  const {
    // error: createSurveyError,
    isLoading: createSurveyIsLoading,
    data: survey,
    run: createSurvey,
  } = useCreateSurvey();

  const {
    // error: updateSurveyError,
    isLoading: updateSurveyIsLoading,
    run: updateSurvey,
  } = useUpdateSurvey();

  useEffect(() => {
    if (survey?.rating && surveyData.rating !== survey.rating) {
      setStep(1);
      setSurveyData({
        ...surveyData,
        rating: survey.rating,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  const getContentBysStep = () => {
    if (createSurveyIsLoading || updateSurveyIsLoading) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <RoutalLoader color={project?.style?.primary_color?.value} />
        </Grid>
      );
    }

    switch (step) {
      case 0:
        return (
          <>
            <Text variant="h6" style={{ textAlign: `center` }} isBold intlId="customers.survey.step-0.title" />

            <Grid container direction="row" alignItems="center" justifyContent="center" style={{ margin: `32px 0` }}>
              {Array(5)
                .fill(0)
                .map((_, index) => {
                  const isSelected = index + 1 <= (surveyData.rating ?? 0);

                  return (
                    <Grid
                      key={`star-${index}`}
                      item
                      style={{
                        cursor: `pointer`,
                        width: `40px`,
                        height: `40px`,
                        marginLeft: index !== 0 ? `8px` : `0px`,
                      }}
                      onClick={() => {
                        createSurvey({
                          rating: index + 1,
                          stop: stopState.stop!,
                        });
                      }}
                    >
                      <RatingStar isSelected={isSelected} style={{ width: `40px`, height: `40px` }} />
                    </Grid>
                  );
                })}
            </Grid>
          </>
        );
      case 1:
        return (
          <Grid container direction="column" style={{ padding: `12px 25px` }}>
            <Text
              variant="h6"
              isBold
              intlId={`customers.survey.step-1.${(surveyData.rating ?? 0) <= 2 ? `bad` : `good`}.title`}
              style={{ textAlign: `center` }}
            />

            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{
                margin: `32px 0`,
                display: `grid`,
                gridTemplateColumns: `minmax(120px, 140px) minmax(120px, 140px)`,
                gap: `1rem`,
              }}
            >
              {ratingTags.map((ratingTag) => {
                const selected = surveyData.rating_tag === ratingTag;
                const isBad = (surveyData.rating ?? 0) <= 2;
                let Icon;
                switch (ratingTag) {
                  case `time`:
                    Icon = isBad ? BadTime : GoodTime;
                    break;
                  case `condition`:
                    Icon = isBad ? BadCondition : GoodCondition;
                    break;
                  case `driver`:
                    Icon = isBad ? BadDriver : GoodDriver;
                    break;
                  case `info`:
                    Icon = isBad ? BadInfo : GoodInfo;
                    break;
                }
                return (
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: `120px`,
                      cursor: `pointer`,
                      borderRadius: `2px`,
                      padding: `16px`,
                      border: `1px solid ${selected ? RoutalPalette.primary40 : `#E0E0E0`}`,
                    }}
                    onClick={() => {
                      setSurveyData({
                        ...surveyData,
                        rating_tag: surveyData.rating_tag === ratingTag ? undefined : ratingTag,
                      });
                    }}
                  >
                    <Grid item style={{ height: `40px` }}>
                      <Icon style={{ color: `${selected ? RoutalPalette.primary40 : `#CCCECF`}` }} />
                    </Grid>

                    <Grid item style={{ height: `100%` }}>
                      <Text
                        style={{
                          color: `${selected ? RoutalPalette.primary40 : `#CCCECF`}`,
                          textAlign: `center`,
                          cursor: `pointer`,
                        }}
                        intlId={`customers.survey.rating_tag.${isBad ? `bad` : `good`}.${ratingTag}`}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid container justifyContent="center">
              <SurveySubmitButton
                onClick={() => {
                  updateSurvey({
                    rating_tag: surveyData.rating_tag,
                    survey: survey!,
                  });
                  setStep(2);
                }}
                intlId={surveyData.rating_tag ? `customers.survey.next` : `customers.survey.skip`}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container direction="column">
            <Text variant="h6" isBold intlId="customers.survey.step-2.title" style={{ textAlign: `center` }} />

            <Grid container direction="row" alignItems="center" justifyContent="center" style={{ margin: `32px 0` }}>
              <InputText
                multiline
                placeholder={intl.formatMessage({ id: `customers.survey.comments_ph` })}
                rowsMin={4}
                fieldValue={surveyData.comments || ``}
                onChange={(value) => setSurveyData({ ...surveyData, comments: value })}
                variant="outlined"
                inputContainerStyles={{ width: `100%` }}
              />
            </Grid>

            <Grid container justifyContent="center">
              <SurveySubmitButton
                onClick={() => {
                  updateSurvey({
                    comments: surveyData.comments,
                    survey: survey!,
                  });
                  setStep(3);
                }}
                intlId="customers.survey.send"
              />
            </Grid>
          </Grid>
        );
      case 3: {
        if (!survey) {
          return null;
        }

        return (
          <Grid container direction="column" justifyContent="center">
            <Grid item>
              <Text variant="h6" isBold intlId="customers.survey.step-3.title" style={{ textAlign: `center` }} />
            </Grid>

            <VSpacer medium />

            <Grid item>
              <Text variant="label" intlId="customers.survey.step-3.rating" />
            </Grid>

            <VSpacer small />

            <Grid item container direction="row" alignItems="center">
              {Array(5)
                .fill(0)
                .map((_, index) => {
                  const isSelected = index + 1 <= (survey.rating ?? 0);

                  return (
                    <Grid
                      key={`star-${index}`}
                      item
                      style={{
                        width: `28px`,
                        height: `28px`,
                        marginLeft: index !== 0 ? `8px` : `0px`,
                      }}
                    >
                      <RatingStar isSelected={isSelected} style={{ width: `28px`, height: `28px` }} />
                    </Grid>
                  );
                })}
            </Grid>

            {survey.rating_tag ? (
              <>
                <VSpacer medium />

                <Grid item>
                  <Text variant="label" intlId="customers.survey.step-3.rating_tag" />
                </Grid>

                <VSpacer small />

                <Grid item>
                  <Text intlId={getStopSurveyRatingTagText({ rating: survey.rating, ratingTag: survey.rating_tag })} />
                </Grid>
              </>
            ) : null}

            <VSpacer medium />

            <Grid item>
              <Text variant="label" intlId="customers.survey.step-3.comments" />
            </Grid>

            <VSpacer small />

            <Grid item>
              <Text>{survey.comments ?? intl.formatMessage({ id: `none` })}</Text>
            </Grid>
          </Grid>
        );
      }
      default:
        return null;
    }
  };

  if (stop.status === `pending` || stop.status === `canceled` || !stop.isFieldEnabled(`survey`) || stop.survey) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
      style={{ padding: `20px`, borderRadius: `2px`, border: `1px solid ${RoutalPalette.grey4}`, minHeight: `300px` }}
    >
      {getContentBysStep()}
    </Grid>
  );
};
