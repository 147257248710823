import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppContextProvider } from './context';
import { Customers } from './pages';

export const App = () => {
  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: `offlineFirst`,
      },
      mutations: {
        networkMode: `offlineFirst`,
      },
    },
  });

  console.info(`FORCE DEPLOY - Optimization Live & Driver Stop Management `);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Customers />
      </AppContextProvider>
    </QueryClientProvider>
  );
};
