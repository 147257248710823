import { HexColor, ValueObject } from '@nx-smartmonkey/shared/domain';

type BannerType = `image` | `color`;

interface ProjectStyleProps {
  logo?: string;
  banner_image?: string;
  banner_color?: HexColor;
  banner_type: BannerType;
  primary_color?: HexColor;
  secondary_color?: HexColor;
  dark_mode: boolean;
  show_powered_by: boolean;
  favicon?: string;
  page_title?: string;
}

export class ProjectStyle extends ValueObject<ProjectStyleProps> {
  get logo(): string | undefined {
    return this.props.logo;
  }

  set logo(logo: string | undefined) {
    this.props.logo = logo;
  }

  get banner_image(): string | undefined {
    return this.props.banner_image;
  }

  set banner_image(banner_image: string | undefined) {
    this.props.banner_image = banner_image;
  }

  get banner_color(): HexColor | undefined {
    return this.props.banner_color;
  }

  set banner_color(banner_color: HexColor | undefined) {
    this.props.banner_color = banner_color;
  }

  get banner_type(): BannerType {
    return this.props.banner_type;
  }

  set banner_type(banner_type: BannerType) {
    this.props.banner_type = banner_type;
  }

  get primary_color(): HexColor | undefined {
    return this.props.primary_color;
  }

  set primary_color(primary40: HexColor | undefined) {
    this.props.primary_color = primary40;
  }

  get secondary_color(): HexColor | undefined {
    return this.props.secondary_color;
  }

  set secondary_color(secondary: HexColor | undefined) {
    this.props.secondary_color = secondary;
  }

  get dark_mode(): boolean {
    return this.props.dark_mode;
  }

  set dark_mode(dark_mode: boolean) {
    this.props.dark_mode = dark_mode;
  }

  get show_powered_by(): boolean {
    return this.props.show_powered_by;
  }

  set show_powered_by(show_powered_by: boolean) {
    this.props.show_powered_by = show_powered_by;
  }

  get favicon(): string | undefined {
    return this.props.favicon;
  }

  set favicon(favicon: string | undefined) {
    this.props.favicon = favicon;
  }

  get page_title(): string | undefined {
    return this.props.page_title;
  }

  set page_title(page_title: string | undefined) {
    this.props.page_title = page_title;
  }

  private constructor(props: ProjectStyleProps) {
    super(props);
  }

  static create(props: ProjectStyleProps): ProjectStyle {
    return new ProjectStyle(props);
  }
}
