import { GPSTrackDTO, GPSTrackMapper, LocationDTO, LocationMapper } from '@nx-smartmonkey/shared/domain';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { CustomersStopDTO } from '../domain/customersStop/CustomersStopDTO';
import { CustomersStopField, CustomersStopFieldDTO } from '../domain/customersStop/CustomersStopFields';
import { SurveyDTO } from '../domain/survey/SurveyDTO';
import { SurveyMapper } from './SurveyMapper';

export class CustomersStopMapper {
  static toDomain(customersStop: CustomersStopDTO): CustomersStop {
    const { id, status, fields, stop_fields, stop_completed_fields, stop_canceled_fields, driver_id, driver_status } =
      customersStop;

    const parseFieldsByType = <T>(fields: Array<CustomersStopFieldDTO<T>>): Array<CustomersStopField<T>> =>
      fields.map((field) => {
        switch (field.type) {
          case `location`:
            return {
              ...field,
              value: field.value ? LocationMapper.toDomain(field.value as LocationDTO) : undefined,
            };
          case `gps_track`:
            return {
              ...field,
              value: field.value ? GPSTrackMapper.toDomain(field.value as GPSTrackDTO) : undefined,
            };
          case `survey`:
            return {
              ...field,
              value: field.value ? SurveyMapper.toDomain(field.value as SurveyDTO) : undefined,
            };
          default:
            return field as CustomersStopField<T>;
        }
      });

    return CustomersStop.create(
      {
        status,
        fields: parseFieldsByType(fields),
        stop_fields: parseFieldsByType(stop_fields),
        stop_completed_fields: parseFieldsByType(stop_completed_fields),
        stop_canceled_fields: parseFieldsByType(stop_canceled_fields),
        driver_id,
        driver_status,
      },
      id
    );
  }
}
