import { Entity, GPSTrack, Location } from '@nx-smartmonkey/shared/domain';
import {
  ProjectCustomersAppType,
  ProjectCustomersStopCanceledType,
  ProjectCustomersStopCompletedType,
  ProjectCustomersStopFieldType,
} from '../projectCustomers/ProjectCustomersFields';
import { StatusHistoryTypes } from '../projectCustomers/StatusHistory';
import { TimeOptions } from '../projectCustomers/TimeOptions';
import { Survey } from '../survey/Survey';
import { RouteStatus, StopStatus } from './CustomersStopDTO';
import { CustomersStopField } from './CustomersStopFields';

export interface DriverInfo {
  id?: string;
  label?: string;
  avatar?: string;
}

export interface CustomersStopProps {
  status: StopStatus;
  fields: Array<CustomersStopField<ProjectCustomersAppType>>;
  stop_fields: Array<CustomersStopField<ProjectCustomersStopFieldType>>;
  stop_completed_fields: Array<CustomersStopField<ProjectCustomersStopCompletedType>>;
  stop_canceled_fields: Array<CustomersStopField<ProjectCustomersStopCanceledType>>;
  driver_id?: string;
  driver_status?: RouteStatus;
}

export class CustomersStop extends Entity<CustomersStopProps> {
  get id(): string | undefined {
    return this._id;
  }

  get status(): StopStatus {
    return this.props.status;
  }

  set status(status: StopStatus) {
    this.props.status = status;
  }

  get fields(): Array<CustomersStopField<ProjectCustomersAppType>> {
    return this.props.fields;
  }

  set fields(fields: Array<CustomersStopField<ProjectCustomersAppType>>) {
    this.props.fields = fields;
  }

  get stop_fields(): Array<CustomersStopField<ProjectCustomersStopFieldType>> {
    return this.props.stop_fields;
  }

  get stop_completed_fields(): Array<CustomersStopField<ProjectCustomersStopCompletedType>> {
    return this.props.stop_completed_fields;
  }

  get stop_canceled_fields(): Array<CustomersStopField<ProjectCustomersStopCanceledType>> {
    return this.props.stop_canceled_fields;
  }

  get route_id(): string | undefined {
    return this.props.driver_id;
  }

  set route_id(id: string | undefined) {
    this.props.driver_id = id;
  }

  get route_status(): RouteStatus | undefined {
    return this.props.driver_status;
  }

  set route_status(status: RouteStatus | undefined) {
    this.props.driver_status = status;
  }

  get driver_info(): DriverInfo | undefined {
    const field = this.getField(`driver_info`);
    return field ? (field.value as DriverInfo) : undefined;
  }

  get label(): string | undefined {
    const field = this.getStopField(`label`);
    return field ? (field.value as string) : undefined;
  }

  get phone(): string | undefined {
    const field = this.getStopField(`phone`);
    return field ? (field.value as string) : undefined;
  }

  get survey(): Survey | undefined {
    const field = this.getField(`survey`);
    return field ? (field.value as Survey) : undefined;
  }

  get phone_call(): string | undefined {
    const field = this.getField(`phone_call`);
    return field ? (field.value as string) : undefined;
  }

  get stops_left(): number | undefined {
    const field = this.getField(`stops_left`);
    return field ? (field.value as number) : undefined;
  }

  get customer_text_field(): string | undefined {
    const field = this.getField(`customer_text_field`);
    return field ? (field.value as string) : undefined;
  }

  get report_date(): Date | undefined {
    const field = this.getField(`report_date`);
    return field ? (field.value as Date) : undefined;
  }

  get report_time(): number | undefined {
    const field = this.getField(`report_time`);
    return field ? (field.value as number) : undefined;
  }

  get report_location(): Location | undefined {
    const field = this.getStopCompletedField(`location`);
    return field ? (field.value as Location) : undefined;
  }

  set report_location(location: Location | undefined) {
    const field = this.getStopCompletedField(`location`);
    if (field) {
      field.value = location;
    }
  }

  get location(): Location | undefined {
    const field = this.getField(`stop_position`);
    return field ? (field.value as Location) : undefined;
  }

  get last_position(): GPSTrack | undefined {
    const field = this.getField(`driver_position`);
    return field ? (field.value as GPSTrack) : undefined;
  }

  get execution_date(): Date | undefined {
    const field = this.getField(`execution_date`);
    return field ? (field.value as Date) : undefined;
  }

  get estimated_arrival_time(): number | undefined {
    const field = this.getField(`estimated_arrival_time`);
    return field ? (field.value as number) : undefined;
  }

  get estimated_arrival_time_options(): TimeOptions | undefined {
    const field = this.getField(`estimated_arrival_time`);
    return field?.time_options;
  }

  get planned_arrival_time(): number | undefined {
    const field = this.getField(`planned_arrival_time`);
    return field ? (field.value as number) : undefined;
  }

  get planned_arrival_time_options(): TimeOptions | undefined {
    const field = this.getField(`planned_arrival_time`);
    return field?.time_options;
  }

  get pin(): string | undefined {
    const field = this.getField(`pin`);
    return field ? (field.value as string) : undefined;
  }

  get additional_info(): string | undefined {
    const field = this.getField(`additional_info`);
    return field ? (field.additional_info as string) : undefined;
  }

  private constructor(props: CustomersStopProps, id?: string) {
    super(props, id);
  }

  private getField(id: ProjectCustomersAppType): CustomersStopField<ProjectCustomersAppType> | undefined {
    return this.fields.find((field) => field.id === id);
  }

  isFieldEnabled(id: ProjectCustomersAppType): boolean {
    const field = this.getField(id);
    return Boolean(field);
  }

  getStatusText(status: StatusHistoryTypes): string | undefined {
    const field = this.getField(`status_history`);

    if (!field?.status_history) {
      return undefined;
    }

    const statusHistory = field.status_history.find((item) => item.id === status);
    return statusHistory?.label;
  }

  private getStopField(
    id: ProjectCustomersStopFieldType
  ): CustomersStopField<ProjectCustomersStopFieldType> | undefined {
    return this.stop_fields.find((field) => field.id === id);
  }

  isStopFieldEnabled(id: ProjectCustomersStopFieldType): boolean {
    const field = this.getStopField(id);
    return Boolean(field);
  }

  private getStopCompletedField(
    id: ProjectCustomersStopCompletedType
  ): CustomersStopField<ProjectCustomersStopCompletedType> | undefined {
    return this.stop_completed_fields.find((field) => field.id === id);
  }

  isStopCompletedFieldEnabled(id: ProjectCustomersStopCompletedType): boolean {
    const field = this.getStopCompletedField(id);
    return Boolean(field);
  }

  private getStopCanceledField(
    id: ProjectCustomersStopCanceledType
  ): CustomersStopField<ProjectCustomersStopCanceledType> | undefined {
    return this.stop_canceled_fields.find((field) => field.id === id);
  }

  isStopCanceledFieldEnabled(id: ProjectCustomersStopCanceledType): boolean {
    const field = this.getStopCanceledField(id);
    return Boolean(field);
  }

  static create(props: CustomersStopProps, id?: string) {
    return new CustomersStop(props, id);
  }
}
