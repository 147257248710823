import { Survey } from '../../domain/survey/Survey';
import { SurveyActionType, SurveyActions } from './actions';

export interface SurveyState {
  survey?: Survey;
  surveyInProcess: boolean;
}

export const getInitialState = (): SurveyState => ({
  survey: undefined,
  surveyInProcess: false,
});

export const SurveyReducer = (state: SurveyState, action: SurveyActions): SurveyState => {
  switch (action.type) {
    case SurveyActionType.CREATE_SURVEY:
      return {
        ...state,
        surveyInProcess: true,
        survey: action.payload,
      };
    case SurveyActionType.UPDATE_SURVEY:
      return {
        ...state,
        surveyInProcess: false,
        survey: action.payload,
      };
    default:
      return state;
  }
};
