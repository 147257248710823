import { PropsWithChildren } from 'react';

import { Compose } from '../helpers/Compose';
import { InfoErrorContextProvider } from './infoError';
import { ProjectContextProvider } from './project';
import { StopContextProvider } from './stop';
import { SurveyContextProvider } from './survey';
import { TranslationsContextProvider } from './translations';

export const AppContextProvider = ({ children }: PropsWithChildren) => (
  <Compose
    components={[
      InfoErrorContextProvider,
      StopContextProvider,
      ProjectContextProvider,
      SurveyContextProvider,
      TranslationsContextProvider,
    ]}
  >
    {children}
  </Compose>
);

export default {};
