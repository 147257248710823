import { Survey } from '../domain/survey/Survey';
import { surveyRepository } from '../repositories/survey';
import { SurveyRepository } from '../repositories/survey/SurveyRepository';

class SurveyService {
  constructor(private surveyRepository: SurveyRepository) {}

  create({ rating, stopId }: { rating: number; stopId: string }): Promise<Survey> {
    return this.surveyRepository.create({ rating, stopId });
  }

  save({ survey }: { survey: Survey }): Promise<Survey> {
    return this.surveyRepository.save(survey);
  }
}

export const surveyService = new SurveyService(surveyRepository);
