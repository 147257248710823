import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface StatusHistoryProps {
  color?: string;
  expanded?: boolean;
  style?: React.CSSProperties;
}

export const StatusHistory = ({
  color = RoutalPalette.primary40,
  expanded = false,
  style = {},
  ...props
}: StatusHistoryProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ width: `18px`, height: `18px`, ...style }}>
      {expanded ? (
        <g>
          <path
            fill={color}
            d="m9.46,9.53h3.9c.25-.02.44-.24.43-.49-.02-.23-.2-.41-.43-.43h-3.9s-.92,0-.92,0h-3.9c-.25,0-.46.2-.47.45,0,.25.2.46.45.47,0,0,.01,0,.02,0h3.9"
          />
          <g>
            <circle fill="none" cx="9" cy="9" r="8.5" />
            <path
              fill={color}
              d="m9,17.5C4.31,17.5.5,13.69.5,9S4.31.5,9,.5s8.5,3.81,8.5,8.5-3.81,8.5-8.5,8.5Zm0-16C4.86,1.5,1.5,4.86,1.5,9s3.36,7.5,7.5,7.5,7.5-3.36,7.5-7.5S13.14,1.5,9,1.5Z"
            />
          </g>
        </g>
      ) : (
        <g>
          <path
            fill={color}
            d="m9.46,9.53h3.9c.25-.02.44-.24.43-.49-.02-.23-.2-.41-.43-.43h-3.9s0-3.9,0-3.9c-.01-.25-.23-.45-.49-.43-.23.01-.42.2-.43.43v3.9s-3.9,0-3.9,0c-.25,0-.46.2-.47.45,0,.25.2.46.45.47,0,0,.01,0,.02,0h3.9s0,3.9,0,3.9c.02.25.24.44.49.42.22-.02.4-.2.42-.42v-3.9Z"
          />
          <g>
            <circle fill="none" cx="9" cy="9" r="8.5" />
            <path
              fill={color}
              d="m9,17.5C4.31,17.5.5,13.69.5,9S4.31.5,9,.5s8.5,3.81,8.5,8.5-3.81,8.5-8.5,8.5Zm0-16C4.86,1.5,1.5,4.86,1.5,9s3.36,7.5,7.5,7.5,7.5-3.36,7.5-7.5S13.14,1.5,9,1.5Z"
            />
          </g>
        </g>
      )}
    </SvgIcon>
  );
};
