import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useMutation } from '@tanstack/react-query';
import { surveyService } from '../../application/SurveyService';

import { SurveyActionType } from '../../context/survey/actions';
import { useSurveyContext } from '../../context/survey/context';
import { Survey } from '../../domain/survey/Survey';
import { SurveyRatingTags } from '../../domain/survey/SurveyRatingTags';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseUpdateSurveyProps {
  rating?: number;
  rating_tag?: SurveyRatingTags;
  comments?: string;
  survey: Survey;
}

type UseUpdateSurveyResponseProps = ServiceResponse<
  ({ rating, rating_tag, comments, survey }: UseUpdateSurveyProps) => void,
  Survey
>;

// Hooks act as 'Adapter' layer.
export const useUpdateSurvey = (): UseUpdateSurveyResponseProps => {
  // const queryClient = useQueryClient();

  const { showSnackbarMessage } = useShowSnackbarMessage();
  const { surveyDispatch } = useSurveyContext();

  const { isLoading, isError, isSuccess, mutate } = useMutation(
    ({ rating, rating_tag, comments, survey }: UseUpdateSurveyProps) => {
      survey.update({
        rating,
        rating_tag,
        comments,
      });
      return surveyService.save({ survey });
    },
    {
      onSuccess: (updatedSurvey) => {
        surveyDispatch({
          type: SurveyActionType.UPDATE_SURVEY,
          payload: updatedSurvey,
        });
        // queryClient.invalidateQueries([`customers_stop`, survey.stop_id]);
      },
      onError: (err: any) => {
        console.info(`Update Survey Error:`, err);

        showSnackbarMessage({
          messageIntlId: err.response?.data?.messageId ?? `network_error`,
        });
      },
    }
  );

  return {
    run: ({ rating, rating_tag, comments, survey }: UseUpdateSurveyProps) =>
      mutate({
        rating,
        rating_tag,
        comments,
        survey,
      }),
    success: isSuccess,
    error: isError,
    isLoading: isLoading,
  };
};
