/* eslint-disable class-methods-use-this */
import { Requests } from '@nx-smartmonkey/shared/helpers';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { CustomersStopMapper } from '../../mappers/CustomersStopMapper';
import { StopRepository } from './StopRepository';

export class StopRepositoryApi implements StopRepository {
  constructor(private requests: Requests) {}

  async sendCustomerTextField(stop: CustomersStop, customersTextField?: string): Promise<void> {
    await this.requests.put({
      uri: `/customers/v1/stop/${stop.id}/customer_text_field`,
      data: {
        customer_text_field: customersTextField,
      },
    });
  }

  async findById(id: string): Promise<CustomersStop> {
    const response = await this.requests.get({ uri: `/customers/v1/stop/${id}/public` });
    return CustomersStopMapper.toDomain(response.data);
  }

  async getExampleByProjectId(projectId: string): Promise<CustomersStop> {
    const response = await this.requests.get({ uri: `/customers/v1/project/${projectId}/example` });
    return CustomersStopMapper.toDomain(response.data);
  }
}
