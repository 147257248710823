import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface StopNotFoundProps {
  color?: string;
  style?: React.CSSProperties;
}

export const StopNotFound = ({ color = RoutalPalette.primary40, style = {}, ...props }: StopNotFoundProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 126.76 189.91" style={{ width: `127px`, height: `190px`, ...style }}>
      <g>
        <path
          fill={color}
          d="m97.99,144.87c-.65,1.23-1.34,2.42-2.05,3.58,15.2,3.62,24.44,9.51,24.44,15.01,0,8.75-23.41,18.5-57,18.5s-57-9.75-57-18.5c0-5.52,9.31-11.43,24.6-15.04-.72-1.16-1.41-2.35-2.07-3.58-15.38,3.78-26.53,10.18-26.53,18.62,0,14.08,31.02,22.5,61,22.5s61-8.42,61-22.5c0-8.42-11.08-14.81-26.39-18.59Z"
        />
        <path
          fill={color}
          d="m63.38,168.3c4.83,0,36-17.3,36-52.34,0-19.88-16.12-36-36-36s-36,16.12-36,36c0,34.47,31.17,52.34,36,52.34Zm0-71.34c10.49,0,19,8.51,19,19s-8.51,19-19,19-19-8.51-19-19,8.51-19,19-19Z"
        />
        <path
          fill={color}
          d="m63.38,70.95c4.41,0,8-3.59,8-8s-3.59-8-8-8-8,3.59-8,8,3.59,8,8,8Zm0-12c2.21,0,4,1.79,4,4s-1.79,4-4,4-4-1.79-4-4,1.79-4,4-4Z"
        />
        <path
          fill={color}
          d="m63.38,48.95c4.41,0,8-3.59,8-8V11.95c0-4.41-3.59-8-8-8s-8,3.59-8,8v29c0,4.41,3.59,8,8,8Zm-4-37c0-2.21,1.79-4,4-4s4,1.79,4,4v29c0,2.21-1.79,4-4,4s-4-1.79-4-4V11.95Z"
        />
      </g>
    </SvgIcon>
  );
};
