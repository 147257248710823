import { CustomersProject } from '../domain/customersProject/CustomersProject';
import { CustomersProjectDTO } from '../domain/customersProject/CustomersProjectDTO';
import { ProjectStyleMapper } from './ProjectStyleMapper';

export class CustomersProjectMapper {
  static toDomain(props: CustomersProjectDTO): CustomersProject {
    const { id, organization_id, style } = props;

    return CustomersProject.create(
      {
        organization_id,
        ...(style ? { style: ProjectStyleMapper.toDomain(style) } : {}),
      },
      id
    );
  }
}
