import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';

import { useEffect, useState } from 'react';
import { Communication } from '../assets/svg/Communication';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useShowSnackbarMessage } from '../hooks/snackbarMessage/useShowSnackbarMessage';
import { useUpdateStopCustomerTextField } from '../hooks/stop/useUpdateStopCustomerTextField';
import { Button } from '../react-components/Button';
import { InputText } from '../react-components/InputText';
import { Text } from '../react-components/Text';

interface CustomersCommunicationProps {
  stop: CustomersStop;
  isExample: boolean;
}

export const CustomersCommunication = ({ stop, isExample }: CustomersCommunicationProps) => {
  const { data: project } = useRetrieveProject();
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const [customersTextField, setCustomersTextField] = useState<string>(``);
  const { run: sendCustomerTextField } = useUpdateStopCustomerTextField({ stop });

  useEffect(() => {
    if (stop.isFieldEnabled(`customer_text_field`)) {
      setCustomersTextField(stop.customer_text_field ?? ``);
    }
  }, [stop]);

  if (
    stop.status !== `pending` ||
    ((!stop.isFieldEnabled(`phone_call`) || stop.phone_call === undefined) &&
      !stop.isFieldEnabled(`customer_text_field`))
  ) {
    return null;
  }

  const isCustomerTextFieldEnabled =
    stop.isFieldEnabled(`customer_text_field`) && stop.customer_text_field !== customersTextField;

  return (
    <Grid
      container
      direction="column"
      style={{ padding: `20px`, borderRadius: `2px`, border: `1px solid ${RoutalPalette.grey4}` }}
    >
      <Grid item container alignItems="center">
        <Communication color={project?.style?.primary_color?.value ?? RoutalPalette.primary40} />

        <HSpacer small />

        <Text
          variant="h6"
          style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
          intlId="customers.communication.title"
        />
      </Grid>

      <VSpacer small />

      {stop.isFieldEnabled(`customer_text_field`) ? (
        <Grid item container direction="column">
          <Text variant="body-large" intlId="customers.communication.customer_text_field" />

          <VSpacer small />

          <Text variant="label" intlId="customers.communication.customer_text_field.info" />

          <VSpacer small />

          <InputText
            multiline
            placeholderIntlId="customers.communication.customer_text_field.ph"
            fieldValue={customersTextField}
            onChange={(text) => setCustomersTextField(text)}
            focusedBorderColor={project?.style?.primary_color?.value}
          />

          <VSpacer small />

          <Button
            style={{ backgroundColor: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
            intlId="customers.communication.customer_text_field.send"
            disabled={customersTextField === `` || !isCustomerTextFieldEnabled}
            onClick={() => {
              if (isExample) {
                showSnackbarMessage({
                  type: `success`,
                  messageIntlId: `customers.communication.customer_text_field.send_succeeded`,
                });
              } else {
                sendCustomerTextField({ customersTextField });
              }
            }}
          />
          {stop.isFieldEnabled(`phone_call`) ? <VSpacer medium /> : null}
        </Grid>
      ) : null}

      {stop.isFieldEnabled(`phone_call`) && stop.phone_call !== undefined ? (
        <Grid item container direction="column">
          <Text variant="body-large" intlId="customers.communication.phone_call" />

          <VSpacer small />

          <Text variant="label" intlId="customers.communication.phone_call.info" />

          <VSpacer small />

          <Button
            styleType="secondary"
            style={{
              backgroundColor: project?.style?.secondary_color?.value ?? RoutalPalette.button.secondary.defaultColor,
            }}
            intlId="customers.communication.phone_call.call"
            onClick={() => {
              window.location.href = `tel:${stop.phone_call!.replace(/ /g, ``)}`;
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
