import { HexColor } from '@nx-smartmonkey/shared/domain';

import { ProjectStyle } from '../domain/projectStyle/ProjectStyle';
import { ProjectStyleDTO } from '../domain/projectStyle/ProjectStyleDTO';

export class ProjectStyleMapper {
  static toDomain(props: ProjectStyleDTO): ProjectStyle {
    const {
      logo,
      banner_image,
      banner_color,
      banner_type,
      primary_color,
      secondary_color,
      dark_mode,
      show_powered_by,
      favicon,
      page_title,
    } = props;

    return ProjectStyle.create({
      logo,
      banner_image,
      banner_color: banner_color ? HexColor.create(banner_color) : undefined,
      banner_type,
      primary_color: primary_color ? HexColor.create(primary_color) : undefined,
      secondary_color: secondary_color ? HexColor.create(secondary_color) : undefined,
      dark_mode,
      show_powered_by,
      favicon,
      page_title,
    });
  }
}
