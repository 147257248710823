declare const process: any;

class Configuration {
  CUSTOMERS_BACK_END: any;

  TRANSLATIONS_BACK_END: any;

  MIXPANEL_API_KEY: any;

  SENTRY_DSN: any;

  ENVIRONMENT: string;

  constructor() {
    this.CUSTOMERS_BACK_END = process.env.NX_CUSTOMERS_BACK_END;
    this.TRANSLATIONS_BACK_END = process.env.NX_TRANSLATIONS_BACK_END;
    this.MIXPANEL_API_KEY = process.env.NX_MIXPANEL_API_KEY;
    this.SENTRY_DSN = process.env.NX_SENTRY_DSN;
    this.ENVIRONMENT = process.env.NX_ENVIRONMENT;
  }
}
const configuration = new Configuration();
export default configuration;
