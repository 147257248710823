import { createContext, useContext } from 'react';
import { StopActions } from './actions';
import { getInitialState } from './reducer';

export const StopContext = createContext({
  stopState: getInitialState(),
  stopDispatch: (action: StopActions) => {},
});

StopContext.displayName = `StopContext`;

export const useStopContext = () => useContext(StopContext);
