import { Grid } from '@mui/material';
import { RoutalLoader, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';

import { useSearchParams } from 'react-router-dom';
import { StopNotFound } from '../assets/svg/StopNotFound';
import { CustomersContainer } from '../components/CustomersContainer';
import { StatusHistoryTypes } from '../domain/projectCustomers/StatusHistory';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useRetrieveExampleStop } from '../hooks/stop/useRetrieveExampleStop';
import { Text } from '../react-components/Text';

export const CustomersExample = () => {
  const [searchParams] = useSearchParams();
  const { data: project } = useRetrieveProject();
  const {
    error,
    isLoading,
    data: stop,
  } = useRetrieveExampleStop({
    projectId: project?.id,
    status: (searchParams.get(`status`) ?? `created`) as StatusHistoryTypes,
  });

  if (isLoading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: `100%` }}
        wrap="nowrap"
      >
        <Grid item container alignItems="center" justifyContent="center">
          <RoutalLoader color={project?.style?.primary_color?.value} />
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: `100%`, overflowY: `auto` }}
        wrap="nowrap"
      >
        <Grid item>
          <StopNotFound color={project?.style?.primary_color?.value ?? RoutalPalette.primary40} />
        </Grid>

        <VSpacer large />

        <Grid item>
          <Text variant="h6" intlId="customers.error.service_not_found" />
        </Grid>

        <VSpacer medium />

        <Grid item>
          <Text intlId="customers.error.service_not_found.description" />
        </Grid>
      </Grid>
    );
  }

  return <CustomersContainer stop={stop} isExample />;
};
