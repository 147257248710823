import { Requests } from '@nx-smartmonkey/shared/helpers';
import { CustomersProject } from '../../domain/customersProject/CustomersProject';
import { CustomersProjectMapper } from '../../mappers/CustomersProjectMapper';
import { ProjectRepository } from './ProjectRepository';

export class ProjectRepositoryApi implements ProjectRepository {
  constructor(private requests: Requests) {}
  async findByHost(host: string): Promise<CustomersProject> {
    const response = await this.requests.get({ uri: `/customers/v1/project`, params: { host } });
    return CustomersProjectMapper.toDomain(response.data);
  }
}
