import { Grid } from '@mui/material';
import styled from 'styled-components';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { CustomersAdditionalInfo } from './CustomersAdditionalInfo';
import { CustomersCommunication } from './CustomersCommunication';
import { CustomersMap } from './CustomersMap';
import { CustomersPIN } from './CustomersPIN';
import { CustomersReportCanceledInfo } from './CustomersReportCanceledInfo';
import { CustomersReportCompletedInfo } from './CustomersReportCompletedInfo';
import { CustomersStopInfo } from './CustomersStopInfo';
import { CustomersSurvey } from './CustomersSurvey';
import { CustomersSurveyExample } from './CustomersSurveyExample';
import { CustomersStatusHistory } from './customersStatusHistory';

const ContainerColumns = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 40px;
  @media (min-width: 413px) {
    display: grid;
    grid-template-columns: 1fr 356px;
    grid-gap: 16px;
  }
`;

type CustomersContainerProps = {
  stop?: CustomersStop;
  isExample?: boolean;
};

export const CustomersContainer = ({ stop, isExample = false }: CustomersContainerProps) => {
  if (!stop) {
    return null;
  }

  if (stop.status === `canceled`) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ height: `100%`, overflowY: `auto`, overflowX: `hidden` }}
        wrap="nowrap"
      >
        <CustomersMap stop={stop} />
        <Grid
          item
          container
          direction="column"
          style={{
            width: `100%`,
            maxWidth: ` 616px`,
            padding: `18px`,
          }}
          wrap="nowrap"
        >
          <Grid container direction="column" rowGap="16px" style={{ marginBottom: `40px` }}>
            <CustomersStatusHistory stop={stop} />
            <CustomersStopInfo stop={stop} />
            <CustomersReportCompletedInfo stop={stop} />
            <CustomersReportCanceledInfo stop={stop} />
          </Grid>

          <CustomersAdditionalInfo stop={stop} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ height: `100%`, overflowY: `auto`, overflowX: `hidden` }}
      wrap="nowrap"
    >
      <CustomersMap stop={stop} />
      <Grid
        item
        container
        direction="column"
        style={{
          width: `100%`,
          maxWidth: ` 1024px`,
          padding: `18px`,
        }}
        wrap="nowrap"
      >
        <ContainerColumns>
          <Grid container direction="column" rowGap="16px">
            <CustomersStatusHistory stop={stop} />
            <CustomersStopInfo stop={stop} />
            <CustomersReportCompletedInfo stop={stop} />
            <CustomersReportCanceledInfo stop={stop} />
          </Grid>

          <Grid container direction="column" rowGap="16px">
            <CustomersPIN stop={stop} />
            {isExample ? <CustomersSurveyExample stop={stop} /> : <CustomersSurvey stop={stop} />}
            <CustomersCommunication stop={stop} isExample={isExample} />
          </Grid>
        </ContainerColumns>

        <CustomersAdditionalInfo stop={stop} />
      </Grid>
    </Grid>
  );
};
