import { SvgIcon } from '@mui/material';
import React from 'react';

interface ScheduledProps {
  style?: React.CSSProperties;
}

export const Scheduled = ({ style = {}, ...props }: ScheduledProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" style={{ width: `12px`, height: `12px`, ...style }}>
      <g>
        <path
          fill="white"
          d="m10.44,1.27h-.76v-.74c0-.24-.19-.43-.43-.43s-.43.19-.43.43v.74h-2.4v-.74c0-.24-.19-.43-.43-.43s-.43.19-.43.43v.74h-2.4v-.74c0-.24-.19-.43-.43-.43s-.43.19-.43.43v.74h-.77C.86,1.27.29,1.84.29,2.54v7.96c0,.7.57,1.26,1.26,1.26h8.89c.7,0,1.26-.57,1.26-1.26V2.54c0-.7-.57-1.26-1.26-1.26Zm.33,9.23c0,.18-.15.33-.33.33H1.56c-.18,0-.33-.15-.33-.33V2.54c0-.18.15-.33.33-.33h.77v.74c0,.24.19.43.43.43s.43-.19.43-.43v-.74h2.4v.74c0,.24.19.43.43.43s.43-.19.43-.43v-.74h2.4v.74c0,.24.19.43.43.43s.43-.19.43-.43v-.74h.76c.18,0,.33.15.33.33v7.96Z"
        />
        <path
          fill="white"
          d="m7.68,5.34l-2.52,2.52-.85-.85c-.2-.2-.51-.2-.71,0s-.2.51,0,.71l1.2,1.2c.1.1.23.15.35.15s.26-.05.35-.15l2.87-2.87c.2-.2.2-.51,0-.71s-.51-.2-.71,0Z"
        />
      </g>
    </SvgIcon>
  );
};
