import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { stopService } from '../../application/StopService';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { Survey } from '../../domain/survey/Survey';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseUpdateSurveyProps {
  customersTextField?: string;
}

type UseUpdateStopCustomerTextFieldProps = ServiceResponse<
  ({ customersTextField }: UseUpdateSurveyProps) => void,
  Survey
>;

// Hooks act as 'Adapter' layer.
export const useUpdateStopCustomerTextField = ({
  stop,
}: {
  stop: CustomersStop;
}): UseUpdateStopCustomerTextFieldProps => {
  const queryClient = useQueryClient();
  const { showSnackbarMessage } = useShowSnackbarMessage();

  const { isLoading, isError, isSuccess, mutate } = useMutation(
    ({ customersTextField }: UseUpdateSurveyProps) => {
      return stopService.sendCustomerTextField(stop, customersTextField);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`customers_stop`, stop.id]);

        showSnackbarMessage({
          type: `success`,
          messageIntlId: `customers.communication.customer_text_field.send_succeeded`,
        });
      },
      onError: (err: any) => {
        console.info(`Update Survey Error:`, err);

        showSnackbarMessage({
          messageIntlId: err.response?.data?.messageId ?? `network_error`,
        });
      },
    }
  );

  return {
    run: ({ customersTextField }: UseUpdateSurveyProps) => mutate({ customersTextField }),
    success: isSuccess,
    error: isError,
    isLoading: isLoading,
  };
};
