import React from 'react';
import { StatusHistoryTypes } from '../../domain/projectCustomers/StatusHistory';
import { Canceled } from './status-icons/Canceled';
import { Completed } from './status-icons/Completed';
import { Created } from './status-icons/Created';
import { OnRoute } from './status-icons/OnRoute';
import { Scheduled } from './status-icons/Scheduled';

interface StatusProps {
  status: StatusHistoryTypes;
  style?: React.CSSProperties;
}

export const Status = ({ status, style = {}, ...props }: StatusProps) => {
  switch (status) {
    case `created`:
      return <Created style={style} {...props} />;
    case `scheduled`:
      return <Scheduled style={style} {...props} />;
    case `on_route`:
      return <OnRoute style={style} {...props} />;
    case `completed`:
      return <Completed style={style} {...props} />;
    case `canceled`:
      return <Canceled style={style} {...props} />;
    default:
      return null;
  }
};
