import { RoutalInputText, RoutalInputTextProps } from '@nx-smartmonkey/ui';
import { useIntl } from 'react-intl';

interface InputTextProps extends RoutalInputTextProps {
  placeholderIntlId?: string;
  placeholderIntlValues?: Record<string, string | number>;
}

export const InputText = (props: InputTextProps) => {
  const { placeholder, suffix, placeholderIntlId, placeholderIntlValues } = props;

  const intl = useIntl();
  let processedPlaceholder: string;

  // Select the content of the placeholder
  if (placeholderIntlId) {
    processedPlaceholder = intl.formatMessage({ id: placeholderIntlId }, placeholderIntlValues);
  } else {
    processedPlaceholder = placeholder ?? suffix ?? intl.formatMessage({ id: `none` });
  }
  return <RoutalInputText {...props} placeholder={processedPlaceholder} />;
};
