import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { StopActionType, StopActions } from './actions';

export interface StopState {
  error: boolean;
  isLoading: boolean;
  stop?: CustomersStop;
}

export const getInitialState = (): StopState => ({
  error: false,
  isLoading: false,
  stop: undefined,
});

export const StopReducer = (state: StopState, action: StopActions): StopState => {
  switch (action.type) {
    case StopActionType.FETCH_STOP:
      return {
        error: false,
        isLoading: false,
        stop: action.payload,
      };
    case StopActionType.FETCH_STOP_FAILURE:
      return { ...state, error: true, isLoading: false };
    case StopActionType.LOADING_STOP:
      return { ...state, error: false, isLoading: true };
    default:
      return state;
  }
};
