import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { stopRepository } from '../repositories/stop';
import { StopRepository } from '../repositories/stop/StopRepository';

class StopService {
  constructor(private stopRepository: StopRepository) {}

  findById(id: string): Promise<CustomersStop> {
    return this.stopRepository.findById(id);
  }

  getExampleByProjectId(projectId: string): Promise<CustomersStop> {
    return this.stopRepository.getExampleByProjectId(projectId);
  }

  async sendCustomerTextField(stop: CustomersStop, customersTextField?: string): Promise<void> {
    await this.stopRepository.sendCustomerTextField(stop, customersTextField);
  }
}

export const stopService = new StopService(stopRepository);
