import { Grid, Typography } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';

const TextParser = ({ color = RoutalPalette.primary40, inputString }: { color?: string; inputString: string }) => {
  const phoneRegex = /\+\d{1,3}\s?\d{1,4}[-.\s]?\d{1,3}[-.\s]?\d{2,4}[-.\s]?\d{2,4}[-.\s]?\d{2,4}\b/g;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
  const urlRegex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+\b/g;

  const matches = inputString.match(new RegExp(`${phoneRegex.source}|${emailRegex.source}|${urlRegex.source}`, `g`));

  if (!matches) {
    return <span>{inputString}</span>;
  }

  const parts = inputString.split(
    new RegExp(`(${matches.map((match) => match.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, `\\$&`)).join(`|`)})`)
  );

  return (
    <Typography
      style={{ fontSize: `14px` }}
      sx={{
        textAlign: { xs: `justify`, md: `center` },
      }}
    >
      {parts.map((part, index) => {
        if (part.match(phoneRegex)) {
          const isFirstPart = index === 0 && !part.startsWith(`+`);
          const phoneNumber = isFirstPart ? part.substring(1) : part;
          return (
            <a style={{ color, fontWeight: `normal` }} key={index} href={`tel:${part}`}>
              {isFirstPart ? `+` : ``}
              {phoneNumber}
            </a>
          );
        } else if (part.match(emailRegex)) {
          return (
            <a style={{ color, fontWeight: `normal` }} key={index} href={`mailto:${part}`}>
              {part}
            </a>
          );
        } else if (part.match(urlRegex)) {
          let url = part;
          if (!url.match(/^(?:https?|ftp):\/\//i)) {
            url = `https://${url}`;
          }
          return (
            <a style={{ color, fontWeight: `normal` }} key={index} href={url} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        }
        return <span key={index}>{part}</span>;
      })}
    </Typography>
  );
};

interface CustomersAdditionalInfoProps {
  stop: CustomersStop;
}

export const CustomersAdditionalInfo = ({ stop }: CustomersAdditionalInfoProps) => {
  const { data: project } = useRetrieveProject();

  if (!stop.isFieldEnabled(`additional_info`) || !stop.additional_info) {
    return null;
  }

  return (
    <Grid item container direction="row">
      <TextParser color={project?.style?.primary_color?.value} inputString={stop.additional_info} />
    </Grid>
  );
};
