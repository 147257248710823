import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface LogoProps {
  color?: string;
  style?: React.CSSProperties;
}

export const Logo = ({ color = RoutalPalette.primary40, style = {}, ...props }: LogoProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 16" style={{ width: `13px`, height: `16px`, ...style }}>
      <g>
        <path
          d="M9.55356 10.0854C10.3031 9.64064 10.9197 9.00869 11.3408 8.25369C11.762 7.49869 11.9727 6.6475 11.9516 5.7865C11.9516 3.02485 9.9593 0.765137 6.38212 0.765137H0V3.31542C0.000214451 3.99691 0.275432 4.65042 0.765152 5.13231C1.25487 5.61419 1.91901 5.885 2.61158 5.88521C1.91901 5.88543 1.25487 6.15624 0.765152 6.63812C0.275432 7.12001 0.000214451 7.77352 0 8.45501V15.3659H3.53713V10.9874H6.32104L8.41484 15.3659H12.3176L9.55356 10.0854ZM8.58514 5.89716C7.90604 6.04294 7.28363 6.37716 6.7916 6.86026C6.29958 7.34335 5.95855 7.95507 5.80891 8.62298V8.63214L5.80123 8.60626C5.65168 7.94173 5.31266 7.33294 4.82395 6.85127C4.33524 6.3696 3.71709 6.03504 3.04199 5.88681H3.0339L3.03835 5.88362C3.7155 5.73673 4.33593 5.40262 4.82667 4.92058C5.31741 4.43854 5.65804 3.82862 5.80851 3.16257L5.81175 3.17332C5.96108 3.83711 6.29949 4.4453 6.78736 4.92671C7.27522 5.40811 7.89237 5.74279 8.56653 5.89158L8.58797 5.89596L8.58514 5.89716Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath>
          <rect width="13" height="15" fill={RoutalPalette.neutral00} transform="translate(0 0.765137)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
