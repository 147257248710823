import { SvgIcon } from '@mui/material';
import React from 'react';

interface CompletedProps {
  style?: React.CSSProperties;
}

export const Completed = ({ style = {}, ...props }: CompletedProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" style={{ width: `12px`, height: `12px`, ...style }}>
      <g>
        <path
          fill="white"
          d="m3.81,10.26c-.13,0-.26-.05-.35-.15L.29,6.94c-.2-.2-.2-.51,0-.71s.51-.2.71,0l2.82,2.82L11.03,1.83c.2-.2.51-.2.71,0s.2.51,0,.71l-7.57,7.57c-.1.1-.23.15-.35.15Z"
        />
      </g>
    </SvgIcon>
  );
};
