import { useEffect } from 'react';

import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { stopService } from '../../application/StopService';
import { StopActionType } from '../../context/stop/actions';
import { useStopContext } from '../../context/stop/context';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { StatusHistoryTypes } from '../../domain/projectCustomers/StatusHistory';

type UseRetrieveExampleStopResponseProps = ServiceResponse<void, CustomersStop>;

interface UseRetrieveExampleStopProps {
  projectId?: string;
  status: StatusHistoryTypes;
}

// Hooks act as 'Adapter' layer.
export const useRetrieveExampleStop = ({
  projectId,
  status,
}: UseRetrieveExampleStopProps): UseRetrieveExampleStopResponseProps => {
  const { stopState, stopDispatch } = useStopContext();

  const { isInitialLoading: isLoading, isSuccess } = useQuery(
    [`customers_example_stop`, projectId!],
    () => stopService.getExampleByProjectId(projectId!),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      onSuccess: (customersStopExample) => {
        switch (status) {
          case `created`:
            customersStopExample.status = `pending`;
            customersStopExample.route_id = undefined;
            customersStopExample.report_location = undefined;
            break;
          case `scheduled`:
            customersStopExample.status = `pending`;
            customersStopExample.route_status = `not_started`;
            customersStopExample.report_location = undefined;
            break;
          case `on_route`:
            customersStopExample.status = `pending`;
            customersStopExample.route_status = `in_transit`;
            customersStopExample.report_location = undefined;
            break;
          case `completed`:
            customersStopExample.status = `completed`;
            break;
          case `canceled`:
            customersStopExample.status = `canceled`;
            customersStopExample.report_location = undefined;
            break;
        }
        stopDispatch({
          type: StopActionType.FETCH_STOP,
          payload: customersStopExample,
        });
      },
      onError: (err: any) => {
        console.info(`Retrieve Example Stop Error: `, err?.response);
        stopDispatch({ type: StopActionType.FETCH_STOP_FAILURE });
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      stopDispatch({ type: StopActionType.LOADING_STOP });
    }
  }, [stopDispatch, isLoading]);

  return {
    success: isSuccess,
    error: stopState.error,
    isLoading: stopState.isLoading,
    data: stopState.stop,
    run: undefined,
  };
};
