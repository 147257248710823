import { LanguageType } from '@nx-smartmonkey/shared/domain';
import { Requests } from '@nx-smartmonkey/shared/helpers';
import { TranslationsRepository } from './TranslationsRepository';

export class TranslationsRepositoryApi implements TranslationsRepository {
  constructor(private requests: Requests) {}
  async getTranslations({
    languages,
  }: {
    languages: LanguageType[];
  }): Promise<{ language: LanguageType; translations: Record<string, string> }[]> {
    const requests = languages.map((language) => {
      return {
        method: this.requests.get.bind(this.requests),
        params: {
          uri: `/locale`,
          params: {
            language,
            domain: `customers`,
          },
        },
      };
    });

    const response = await this.requests.multipleAsyncWait({
      requests,
    });

    return response.map((translations, index) => ({
      language: languages[index],
      translations,
    }));
  }
}
