/* eslint-disable camelcase */
import { Survey } from '../domain/survey/Survey';
import { SurveyDTO } from '../domain/survey/SurveyDTO';
import { SurveyRepositoryDTO } from '../domain/survey/SurveyRepositoryDTO';

export class SurveyMapper {
  static toDomain(stop: SurveyDTO): Survey {
    const { id, stop_id, route_id, rating, rating_tag, comments, deleted, created_at, updated_at, deleted_at } = stop;

    return Survey.create(
      {
        stop_id: stop_id,
        route_id: route_id,
        rating,
        rating_tag: rating_tag,
        comments,
        deleted,
        created_at: created_at,
        updated_at: updated_at,
        deleted_at: deleted_at,
      },
      id
    );
  }

  static toRepository(stop: Survey): SurveyRepositoryDTO {
    return {
      rating: stop.rating,
      rating_tag: stop.rating_tag,
      comments: stop.comments,
    };
  }
}
