import { RoutalPalette } from '@nx-smartmonkey/ui';

import styled from 'styled-components';
import { ProjectStyle } from '../domain/projectStyle/ProjectStyle';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';

export const HEADER_HEIGHT = 70;

type HeaderBackgroundProps = {
  projectStyle?: ProjectStyle;
};

const HeaderBackground = styled.div<HeaderBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  z-index: -1;
  ${(props: HeaderBackgroundProps) => {
    if (props.projectStyle?.banner_type === `image`) {
      if (!props.projectStyle?.banner_image) {
        return `
          :before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color:  ${RoutalPalette.primary20};
            z-index: -1;
          }
        `;
      } else {
        return `
          opacity: 0.3;
          background-image: url(${props.projectStyle?.banner_image});
          background-size: cover;
        `;
      }
    }

    return `
      background-color: ${props.projectStyle?.banner_color?.value || RoutalPalette.secondary00};
      opacity: ${props.projectStyle?.banner_color?.value ? 0.3 : 1};
    `;
  }}
`;

const HeaderContainer = styled.header`
  position: sticky;
  height: ${HEADER_HEIGHT}px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

export const CustomersHeader = () => {
  const { data: project, isLoading, success, error } = useRetrieveProject();

  if (isLoading || (!success && !error)) {
    return null;
  }

  return (
    <HeaderContainer>
      <HeaderBackground projectStyle={project?.style} />
      {project?.style?.logo ? <img height="70px" src={project?.style?.logo} alt="" /> : null}
    </HeaderContainer>
  );
};
