import { CustomFieldDefinitionTypes } from '@nx-smartmonkey/shared/domain';
import { CustomersStopField } from '../domain/customersStop/CustomersStopFields';
import { Text } from '../react-components/Text';

interface CustomFieldValueProps {
  field: CustomersStopField<string>;
}

export const CustomFieldValue = ({ field }: CustomFieldValueProps) => {
  if (!field.is_custom_field || !field.value) {
    return null;
  }

  switch (field.type as CustomFieldDefinitionTypes) {
    case `categorical`: {
      const values = (typeof field.value === typeof `abc` ? [field.value] : field.value) as string[];
      return (
        <Text>
          {values.reduce((amount, value, index) => {
            return `${amount}${index !== 0 ? `, ` : ``}${value}`;
          }, ``)}
        </Text>
      );
    }
    case `text`:
      return <Text>{field.value as string}</Text>;
    case `numerical`:
      return <Text>{field.value as number}</Text>;
    case `boolean`:
      return <Text intlId={field.value ? `yes` : `no`} />;
    default:
      return null;
  }
};
