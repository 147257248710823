/* eslint-disable class-methods-use-this */
import { Requests } from '@nx-smartmonkey/shared/helpers';
import { Survey } from '../../domain/survey/Survey';
import { SurveyMapper } from '../../mappers/SurveyMapper';
import { SurveyRepository } from './SurveyRepository';

export class SurveyRepositoryApi implements SurveyRepository {
  constructor(private requests: Requests) {}

  async save(survey: Survey): Promise<Survey> {
    const response = await this.requests.put({
      uri: `/customers/v1/stop/${survey.stop_id}/survey`,
      data: SurveyMapper.toRepository(survey),
    });
    return SurveyMapper.toDomain(response.data);
  }

  async create({ rating, stopId }: { rating: number; stopId: string }): Promise<Survey> {
    const response = await this.requests.post({
      uri: `/customers/v1/stop/${stopId}/survey`,
      data: {
        rating,
      },
    });
    return SurveyMapper.toDomain(response.data);
  }
}
