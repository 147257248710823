import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useMutation } from '@tanstack/react-query';
import { surveyService } from '../../application/SurveyService';

import { SurveyActionType } from '../../context/survey/actions';
import { useSurveyContext } from '../../context/survey/context';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { Survey } from '../../domain/survey/Survey';
import { useShowSnackbarMessage } from '../snackbarMessage/useShowSnackbarMessage';

interface UseCreateSurveyProps {
  rating: number;
  stop: CustomersStop;
}

type UseCreateSurveyResponseProps = ServiceResponse<({ rating, stop }: UseCreateSurveyProps) => void, Survey>;

// Hooks act as 'Adapter' layer.
export const useCreateSurvey = (): UseCreateSurveyResponseProps => {
  const { showSnackbarMessage } = useShowSnackbarMessage();
  const { surveyState, surveyDispatch } = useSurveyContext();

  const { isLoading, isError, isSuccess, mutate } = useMutation(
    ({ rating, stop }: UseCreateSurveyProps) => {
      return surveyService.create({ rating, stopId: stop.id! });
    },
    {
      onSuccess: (survey) => {
        surveyDispatch({ type: SurveyActionType.CREATE_SURVEY, payload: survey });
      },
      onError: (err: any) => {
        console.info(`Create Survey Error:`, err);

        showSnackbarMessage({
          messageIntlId: err.response?.data?.messageId ?? `network_error`,
        });
      },
    }
  );

  return {
    run: ({ rating, stop }: UseCreateSurveyProps) => mutate({ rating, stop }),
    error: isError,
    success: isSuccess,
    isLoading: isLoading,
    data: surveyState.survey,
  };
};
