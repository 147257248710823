import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';

import { PIN } from '../assets/svg/PIN';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { Text } from '../react-components/Text';

interface CustomersPINProps {
  stop: CustomersStop;
}

export const CustomersPIN = ({ stop }: CustomersPINProps) => {
  const { data: project } = useRetrieveProject();

  if (stop.status !== `pending` || !stop.isFieldEnabled(`pin`)) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      style={{ padding: `20px`, borderRadius: `2px`, border: `1px solid ${RoutalPalette.grey4}` }}
    >
      <Grid item container alignItems="center">
        <PIN color={project?.style?.primary_color?.value ?? RoutalPalette.primary40} />

        <HSpacer small />

        <Text
          variant="h6"
          style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
          intlId="customers.pin.title"
        />
      </Grid>

      <VSpacer small />

      <Grid item>
        <Text intlId="customers.pin.info" />
      </Grid>

      <VSpacer medium />

      <Grid item container direction="row" justifyContent="center">
        <Text variant="h6" isBold>
          {stop.pin}
        </Text>
      </Grid>
    </Grid>
  );
};
