import { Grid } from '@mui/material';
import { ReportImage } from '@nx-smartmonkey/shared/domain';
import { RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';

import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useIsExampleURL } from '../hooks/useIsExampleURL';
import { Text } from '../react-components/Text';
import { CustomFieldValue } from './CustomFieldValue';

const cancelReasons = [
  {
    translationId: `customers.stop_canceled_fields.cancel_reason.reason.nobody`,
    tag: `nobody`,
  },
  {
    translationId: `customers.stop_canceled_fields.cancel_reason.reason.wrong_address`,
    tag: `wrong_address`,
  },
  {
    translationId: `customers.stop_canceled_fields.cancel_reason.missing_information`,
    tag: `missing_information`,
  },
  {
    translationId: `customers.stop_canceled_fields.cancel_reason.reason.other`,
    tag: `other`,
  },
];

interface CustomersReportCanceledInfoProps {
  stop: CustomersStop;
}

export const CustomersReportCanceledInfo = ({ stop }: CustomersReportCanceledInfoProps) => {
  const { isExampleURL } = useIsExampleURL();

  if (stop.status !== `canceled`) {
    return null;
  }

  const enabledFields = stop.stop_canceled_fields.filter((field) => {
    if (field.id === `images`) {
      return (field.value as ReportImage[]).length > 0;
    }
    return true;
  });

  if (enabledFields.length === 0) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      style={{ padding: `20px`, borderRadius: `2px`, border: `1px solid ${RoutalPalette.grey4}` }}
    >
      {stop.stop_canceled_fields.map((field) => {
        switch (field.id) {
          case `images`:
            if (((field.value ?? []) as ReportImage[]).length > 0) {
              return (
                <Grid item container direction="column" key={field.id}>
                  <Text variant="label" intlId={`customers.stop_canceled_fields.${field.id}`} />

                  <VSpacer small />

                  <Grid container direction="row" gap={2}>
                    {(field.value as ReportImage[]).map((image) => {
                      try {
                        const imageUrl = isExampleURL ? image.url.split(`/public`)[0] : image.url;
                        return (
                          <div
                            key={image.id}
                            style={{
                              background: `url(${imageUrl})`,
                              backgroundSize: `cover`,
                              backgroundPosition: `center`,
                              width: `300px`,
                              height: `300px`,
                              borderRadius: `2px`,
                            }}
                          />
                        );
                      } catch (error) {
                        console.warn(`Image ${image.id} with URL ${image.url} could not be loaded. Error:`, error);
                        return null;
                      }
                    })}
                  </Grid>

                  <VSpacer medium />
                </Grid>
              );
            }
            return null;
          case `cancel_reason`:
            if (field.value) {
              const foundReason = cancelReasons.find((cancelReason: any) => cancelReason.tag === field.value);
              if (!foundReason) {
                return null;
              }
              return (
                <Grid item key={field.id}>
                  <Text variant="label" intlId={`customers.stop_canceled_fields.${field.id}`} />

                  <VSpacer small />

                  <Text intlId={`${foundReason.translationId}`} />

                  <VSpacer medium />
                </Grid>
              );
            }
            return null;
          default:
            if (field.is_custom_field) {
              return (
                <Grid item key={field.id}>
                  <Text variant="label">{field.label}</Text>

                  <VSpacer small />

                  <CustomFieldValue field={field} />

                  <VSpacer medium />
                </Grid>
              );
            }
            return (
              <Grid item key={field.id}>
                {field.is_custom_field ? (
                  <Text variant="label">{field.label}</Text>
                ) : (
                  <Text variant="label" intlId={`customers.stop_canceled_fields.${field.id}`} />
                )}

                <VSpacer small />

                <Text>{(field as any).value}</Text>

                <VSpacer medium />
              </Grid>
            );
        }
      })}
    </Grid>
  );
};
