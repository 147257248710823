import { Entity } from '@nx-smartmonkey/shared/domain';
import { ProjectStyle } from '../projectStyle/ProjectStyle';

export interface CustomersProjectProps {
  organization_id: string;
  style?: ProjectStyle;
}

export class CustomersProject extends Entity<CustomersProjectProps> {
  get id(): string | undefined {
    return this._id;
  }

  get organization_id(): string {
    return this.props.organization_id;
  }

  get style(): ProjectStyle | undefined {
    return this.props.style;
  }

  private constructor(props: CustomersProjectProps, id?: string) {
    super(props, id);
  }

  static create(props: CustomersProjectProps, id?: string) {
    return new CustomersProject(props, id);
  }
}
