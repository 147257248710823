import { Grid } from '@mui/material';
import { Location, ReportImage } from '@nx-smartmonkey/shared/domain';
import { RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';

import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useIsExampleURL } from '../hooks/useIsExampleURL';
import { Text } from '../react-components/Text';
import { CustomFieldValue } from './CustomFieldValue';

interface CustomersReportCompletedInfoProps {
  stop: CustomersStop;
}

export const CustomersReportCompletedInfo = ({ stop }: CustomersReportCompletedInfoProps) => {
  const { isExampleURL } = useIsExampleURL();

  if (stop.status !== `completed` && stop.status !== `incomplete`) {
    return null;
  }

  const enabledFields = stop.stop_completed_fields.filter((field) => {
    if (field.id === `images`) {
      return (field.value as ReportImage[]).length > 0;
    }
    if (field.id !== `location`) {
      return field.value !== undefined;
    }
    return false;
  });

  if (enabledFields.length === 0) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{ padding: `20px`, borderRadius: `2px`, border: `1px solid ${RoutalPalette.grey4}` }}
    >
      {enabledFields.map((field) => {
        switch (field.id) {
          case `images`:
            if (((field.value ?? []) as ReportImage[]).length > 0) {
              return (
                <Grid item container direction="column" key={field.id}>
                  <Text variant="label" intlId={`customers.stop_completed_fields.${field.id}`} />

                  <VSpacer small />

                  <Grid container direction="row" gap={2}>
                    {(field.value as ReportImage[]).map((image) => {
                      try {
                        const imageUrl = isExampleURL ? image.url.split(`/public`)[0] : image.url;
                        return (
                          <div
                            key={image.id}
                            style={{
                              background: `url(${imageUrl})`,
                              backgroundSize: `cover`,
                              backgroundPosition: `center`,
                              width: `300px`,
                              height: `300px`,
                              borderRadius: `2px`,
                            }}
                          />
                        );
                      } catch (error) {
                        console.warn(`Image ${image.id} with URL ${image.url} could not be loaded. Error:`, error);
                        return null;
                      }
                    })}
                  </Grid>

                  <VSpacer medium />
                </Grid>
              );
            }
            return null;
          case `signature`:
            if (field.value) {
              const signature = field.value as ReportImage;
              const imageUrl = isExampleURL ? signature.url.split(`/public`)[0] : signature.url;
              return (
                <Grid item container direction="column" key={field.id}>
                  <Text variant="label" intlId={`customers.stop_completed_fields.${field.id}`} />

                  <VSpacer small />

                  <div
                    key={signature.id}
                    style={{
                      background: `url(${imageUrl})`,
                      backgroundSize: `cover`,
                      backgroundPosition: `center`,
                      width: `300px`,
                      height: `300px`,
                      borderRadius: `2px`,
                    }}
                  />

                  <VSpacer medium />
                </Grid>
              );
            }
            return null;
          case `location`:
            if (field.value) {
              return (
                <Grid item key={field.id}>
                  <Text>{(field.value as Location).label}</Text>

                  <VSpacer medium />
                </Grid>
              );
            }
            return null;
          default:
            if (field.value) {
              if (field.is_custom_field) {
                return (
                  <Grid item key={field.id}>
                    <Text variant="label">{field.label}</Text>

                    <VSpacer small />

                    <CustomFieldValue field={field} />

                    <VSpacer medium />
                  </Grid>
                );
              }
              return (
                <Grid item key={field.id}>
                  {field.is_custom_field ? (
                    <Text variant="label">{field.label}</Text>
                  ) : (
                    <Text variant="label" intlId={`customers.stop_completed_fields.${field.id}`} />
                  )}

                  <VSpacer small />

                  <Text>{(field as any).value}</Text>

                  <VSpacer medium />
                </Grid>
              );
            }
            return null;
        }
      })}
    </Grid>
  );
};
