import { AvatarIcon, RoutalDefaultColors } from '@nx-smartmonkey/ui';
import React from 'react';

interface VehicleAvatarProps {
  color?: string;
  vehicleAvatar?: string;
  additionalStyles?: React.CSSProperties;
}

export const VehicleAvatar = ({
  color = RoutalDefaultColors[0],
  vehicleAvatar,
  additionalStyles = {},
}: VehicleAvatarProps) => {
  if (vehicleAvatar) {
    return (
      <img
        draggable="false"
        src={vehicleAvatar}
        alt=""
        style={{
          border: `2px solid ${color}`,
          width: `calc(34px - 4px)`,
          height: `calc(34px - 4px)`,
          borderRadius: `50%`,
          ...additionalStyles,
        }}
      />
    );
  }

  return (
    <AvatarIcon
      color={color}
      style={{
        width: `34px`,
        height: `34px`,
        ...additionalStyles,
      }}
    />
  );
};
