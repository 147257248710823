import { SvgIcon } from '@mui/material';
import React from 'react';
import tinycolor from 'tinycolor2';

interface AvatarIconProps {
  color?: string;
  style?: React.CSSProperties;
}

export default function AvatarIcon({ color = `#393939`, style = {}, ...props }: AvatarIconProps) {
  const tColor = tinycolor(color);
  const lightenColor = tColor.lighten(35).toString();

  return (
    <SvgIcon {...props} viewBox="0 0 34.2 34.12" style={{ width: `34px`, height: `34px`, color, ...style }}>
      <g>
        <circle fill={lightenColor} cx="17.1" cy="17.1" r="17" />
        <g>
          <circle fill={color} cx="17.1" cy="12.74" r="5.5" />
          <path
            fill={color}
            d="M17.1,.1C7.72,.1,.1,7.72,.1,17.1c0,4.15,1.5,7.95,3.97,10.9h0l.05,.05c3.12,3.7,7.78,6.05,12.98,6.05,9.37,0,17-7.63,17-17S26.47,.1,17.1,.1ZM2.1,17.1C2.1,8.82,8.83,2.1,17.1,2.1s15,6.73,15,15c0,3.36-1.17,6.55-3.04,9.05-1.32-2.24-4.93-5.97-11.99-5.97s-10.25,3.65-11.9,5.96c-1.91-2.52-3.06-5.65-3.06-9.05Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
