import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { App } from './app/App';
import { sentryService } from './app/application/SentryService';

sentryService.init();

const root = ReactDOM.createRoot(document.getElementById(`root`) as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
