import { SvgIcon } from '@mui/material';
import React from 'react';

interface CreatedProps {
  style?: React.CSSProperties;
}

export const Created = ({ style = {}, ...props }: CreatedProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" style={{ width: `12px`, height: `12px`, ...style }}>
      <g>
        <path
          fill="white"
          d="m1.66,10.1c.07,0,.15-.01.22-.04l2.15-.71h0c.29-.1.55-.26.77-.47l5.67-5.56c.73-.72.74-1.89.03-2.62-.72-.73-1.89-.74-2.62-.03L2.22,6.22c-.23.23-.4.51-.5.81l-.71,2.17c-.08.25-.02.52.17.7.13.13.31.2.48.2ZM9.17,1.15c.22,0,.44.09.61.26.33.34.32.88-.01,1.21l-.35.34-1.21-1.21.36-.35c.17-.16.38-.24.6-.24ZM2.66,7.34c.05-.15.14-.29.25-.41L7.5,2.45l1.21,1.21-4.61,4.51c-.11.11-.24.19-.39.24l-1.56.52.52-1.58Z"
        />
        <path fill="white" d="m10.69,10.91H1.31c-.28,0-.5.22-.5.5s.22.5.5.5h9.38c.28,0,.5-.22.5-.5s-.22-.5-.5-.5Z" />
      </g>
    </SvgIcon>
  );
};
