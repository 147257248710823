import { PropsWithChildren, useReducer } from 'react';

import { SurveyContext } from './context';
import { SurveyReducer, getInitialState } from './reducer';

export const SurveyContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(SurveyReducer, initialState);
  return (
    <SurveyContext.Provider
      value={{
        surveyState: state,
        surveyDispatch: dispatch,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
