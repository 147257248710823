import { Grid } from '@mui/material';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';
import { CustomersCurrentStatusPill } from './CustomersCurrentStatusPill';

interface CustomersStatusHistoryProps {
  stop: CustomersStop;
}

export const CustomersStatusHistory = ({ stop }: CustomersStatusHistoryProps) => {
  if (!stop.isFieldEnabled(`status_history`)) {
    return null;
  }

  return (
    <Grid container direction="column" wrap="nowrap">
      <CustomersCurrentStatusPill stop={stop} />
    </Grid>
  );
};
