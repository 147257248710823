import { Entity } from '@nx-smartmonkey/shared/domain';
import { SurveyRatingTags } from './SurveyRatingTags';

interface SurveyUpdateProps {
  rating?: number;
  rating_tag?: SurveyRatingTags;
  comments?: string;
}

interface SurveyProps {
  stop_id: string;
  route_id: string;
  rating: number;
  rating_tag?: SurveyRatingTags;
  comments?: string;
  deleted?: boolean;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export class Survey extends Entity<SurveyProps> {
  get id(): string | undefined {
    // eslint-disable-next-line no-underscore-dangle
    return this._id;
  }

  get stop_id(): string {
    return this.props.stop_id;
  }

  get route_id(): string {
    return this.props.route_id;
  }

  get rating(): number {
    return this.props.rating;
  }

  set rating(rating: number) {
    this.props.rating = rating;
  }

  get rating_tag(): SurveyRatingTags | undefined {
    return this.props.rating_tag;
  }

  set rating_tag(ratingTag: SurveyRatingTags | undefined) {
    this.props.rating_tag = ratingTag;
  }

  get comments(): string | undefined {
    return this.props.comments;
  }

  set comments(comments: string | undefined) {
    this.props.comments = comments;
  }

  get deleted(): boolean | undefined {
    return this.props.deleted;
  }

  get created_at(): Date | undefined {
    return this.props.created_at;
  }

  get updated_at(): Date | undefined {
    return this.props.updated_at;
  }

  get deleted_at(): Date | undefined {
    return this.props.deleted_at;
  }

  private constructor(props: SurveyProps, id?: string) {
    super(props, id);
  }

  static create(props: SurveyProps, id?: string) {
    return new Survey(props, id);
  }

  update({ rating, rating_tag, comments }: SurveyUpdateProps) {
    if (rating) this.rating = rating;
    this.rating_tag = rating_tag;
    this.comments = comments;
  }
}
