import { PropsWithChildren, useReducer } from 'react';

import { StopContext } from './context';
import { StopReducer, getInitialState } from './reducer';

export const StopContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const initialState = getInitialState();
  const [state, dispatch] = useReducer(StopReducer, initialState);
  return (
    <StopContext.Provider
      value={{
        stopState: state,
        stopDispatch: dispatch,
      }}
    >
      {children}
    </StopContext.Provider>
  );
};
