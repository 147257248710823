import { ServiceResponse } from '@nx-smartmonkey/shared/hooks';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { stopService } from '../../application/StopService';
import { StopActionType } from '../../context/stop/actions';
import { useStopContext } from '../../context/stop/context';
import { CustomersStop } from '../../domain/customersStop/CustomersStop';

interface UseRetrieveStopProps {
  stopId: string;
}

type UseRetrieveStopResponseProps = ServiceResponse<void, CustomersStop>;

// Hooks act as 'Adapter' layer.
export const useRetrieveStop = ({ stopId }: UseRetrieveStopProps): UseRetrieveStopResponseProps => {
  const { stopState, stopDispatch } = useStopContext();

  const { isInitialLoading: isLoading, isSuccess } = useQuery(
    [`customers_stop`, stopId],
    () => stopService.findById(stopId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: (customersStop) => {
        stopDispatch({
          type: StopActionType.FETCH_STOP,
          payload: customersStop,
        });
      },
      onError: (err: any) => {
        console.info(`Retrieve Stop Error: `, err?.response);
        stopDispatch({ type: StopActionType.FETCH_STOP_FAILURE });
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      stopDispatch({ type: StopActionType.LOADING_STOP });
    }
  }, [stopDispatch, isLoading]);

  return {
    success: isSuccess,
    error: stopState.error,
    isLoading: stopState.isLoading,
    data: stopState.stop,
    run: undefined,
  };
};
