import { createContext, useContext } from 'react';
import { SurveyActions } from './actions';
import { getInitialState } from './reducer';

export const SurveyContext = createContext({
  surveyState: getInitialState(),
  surveyDispatch: (action: SurveyActions) => {},
});

SurveyContext.displayName = `SurveyContext`;

export const useSurveyContext = () => useContext(SurveyContext);
