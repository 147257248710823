import { RoutalText, RoutalTextProps } from '@nx-smartmonkey/ui';
import React from 'react';
import { useIntl } from 'react-intl';

interface TextProps extends RoutalTextProps {
  intlId?: string;
  intlValues?: Record<string, string | number | ((chunks: any) => React.ReactElement)>;
}

/**
 * General purpose text component. Allows different types and tagNames. An intl-id can be provided to this
 * component.
 * @param props
 */
export const Text = (props: React.PropsWithChildren<TextProps> & React.DOMAttributes<TextProps>) => {
  // TODO: Revisar perquè només està funcionant el cas de BR!!
  const defaultValues = {
    br: <br />,
    span: (chunks: any) => <span>{chunks}</span>,
    ul: (chunks: any) => <ul>{chunks}</ul>,
    li: (chunks: any) => <li>{chunks}</li>,
    b: (chunks: any) => <b>{chunks}</b>,
  };

  const { children, intlId, intlValues } = props;

  const intl = useIntl();
  let processedChildren;

  // Select the content of the class
  if (intlId) {
    const message = intl.formatMessage(
      {
        id: intlId,
      },
      {
        ...defaultValues,
        ...(intlValues ?? {}),
      }
    );
    processedChildren = message;
  } else {
    processedChildren = children;
  }

  return <RoutalText {...props}>{processedChildren}</RoutalText>;
};
