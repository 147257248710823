import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface TimeProps {
  color?: string;
  style?: React.CSSProperties;
}

export const Time = ({ color = RoutalPalette.primary40, style = {}, ...props }: TimeProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14" style={{ width: `12px`, height: `14px`, ...style }}>
      <g>
        <path
          fill={color}
          d="m6,12.82C2.79,12.82.18,10.21.18,7S2.79,1.18,6,1.18s5.82,2.61,5.82,5.82-2.61,5.82-5.82,5.82Zm0-10.65C3.34,2.18,1.18,4.34,1.18,7s2.16,4.82,4.82,4.82,4.82-2.16,4.82-4.82-2.16-4.82-4.82-4.82Z"
        />
        <path
          fill={color}
          d="m3.84,9.04c-.18,0-.35-.1-.44-.26-.13-.24-.04-.55.2-.68l1.9-1.02v-3.63c0-.28.22-.5.5-.5s.5.22.5.5v3.85c0,.23-.13.45-.33.55l-2.1,1.13c-.08.04-.16.06-.24.06Zm1.86-2.07s0,0,0,0h0Z"
        />
      </g>
    </SvgIcon>
  );
};
