import { useMediaQuery } from '@mui/material';
import { RoutalButton, RoutalButtonProps, RoutalMobileButton, RoutalTheme } from '@nx-smartmonkey/ui';
import React from 'react';
import { useIntl } from 'react-intl';

export interface ButtonProps extends RoutalButtonProps {
  intlId?: string;
  intlValues?: Record<string, string | number | ((chunks: any) => React.ReactElement)>;
}

export const Button = (
  props: React.PropsWithChildren<ButtonProps> &
    React.HTMLAttributes<HTMLDivElement & ButtonProps> &
    React.ButtonHTMLAttributes<HTMLDivElement & ButtonProps>
) => {
  const isMobile = useMediaQuery(RoutalTheme.breakpoints.down(`sm`));

  const { children, intlId, intlValues } = props;

  const intl = useIntl();
  let processedChildren;

  // Select the content of the class
  if (intlId) {
    const message = intl.formatMessage({ id: intlId }, intlValues ?? {});
    processedChildren = message;
  } else {
    processedChildren = children;
  }

  return isMobile ? (
    <RoutalMobileButton {...props}>{processedChildren}</RoutalMobileButton>
  ) : (
    <RoutalButton {...props}>{processedChildren}</RoutalButton>
  );
};
