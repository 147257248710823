import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface DateProps {
  color?: string;
  style?: React.CSSProperties;
}

export const Date = ({ color = RoutalPalette.primary40, style = {}, ...props }: DateProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14" style={{ width: `12px`, height: `14px`, ...style }}>
      <g>
        <path
          fill={color}
          d="m10.38,1.46h-1.2v-.63c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.63H3.82v-.63c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.63h-1.2c-.72,0-1.3.58-1.3,1.3v9.72c0,.72.58,1.3,1.3,1.3h8.76c.72,0,1.3-.58,1.3-1.3V2.76c0-.72-.58-1.3-1.3-1.3ZM1.62,2.46h1.2v.66c0,.28.22.5.5.5s.5-.22.5-.5v-.66h4.35v.66c0,.28.22.5.5.5s.5-.22.5-.5v-.66h1.2c.17,0,.3.13.3.3v1.81H1.32v-1.81c0-.17.13-.3.3-.3Zm2.98,7.64v-1.85h2.75v1.85h-2.75Zm2.75,1v1.67h-2.75v-1.67h2.75Zm0-5.53v1.69h-2.75v-1.69h2.75Zm-3.75,1.69H1.32v-1.69h2.28v1.69Zm0,1v1.85H1.32v-1.85h2.28Zm4.75,0h2.33v1.85h-2.33v-1.85Zm0-1v-1.69h2.33v1.69h-2.33ZM1.32,12.48v-1.37h2.28v1.67h-1.98c-.17,0-.3-.13-.3-.3Zm9.06.3h-2.03v-1.67h2.33v1.37c0,.17-.13.3-.3.3Z"
        />
      </g>
    </SvgIcon>
  );
};
