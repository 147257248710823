import { Grid } from '@mui/material';
import { RoutalLoader, RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { StopNotFound } from '../assets/svg/StopNotFound';
import { CustomersContainer } from '../components/CustomersContainer';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useRetrieveStop } from '../hooks/stop/useRetrieveStop';
import { Text } from '../react-components/Text';
import { eventsService } from '../services';

let mixpanelPageViewSet = false;

export const CustomersStop = () => {
  const { stopId } = useParams();

  const { data: project } = useRetrieveProject();
  const { error, isLoading, data: stop } = useRetrieveStop({ stopId: stopId! });

  useEffect(() => {
    if (!!project?.organization_id && !mixpanelPageViewSet) {
      eventsService.register({ Platform: `web` });
      eventsService.track({
        eventName: `Page View`,
        params: {
          [`Page Name`]: `Customers`,
          [`Organization ID`]: project.organization_id,
        },
      });
      mixpanelPageViewSet = true;
    }
  }, [project]);

  if (isLoading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: `100%` }}
        wrap="nowrap"
      >
        <Grid item container alignItems="center" justifyContent="center">
          <RoutalLoader color={project?.style?.primary_color?.value} />
        </Grid>
      </Grid>
    );
  }

  if (!stopId || error) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: `100%`, overflowY: `auto` }}
        wrap="nowrap"
      >
        <Grid item>
          <StopNotFound color={project?.style?.primary_color?.value ?? RoutalPalette.primary40} />
        </Grid>

        <VSpacer large />

        <Grid item>
          <Text variant="h6" intlId="customers.error.service_not_found" />
        </Grid>

        <VSpacer medium />

        <Grid item>
          <Text intlId="customers.error.service_not_found.description" />
        </Grid>
      </Grid>
    );
  }

  return <CustomersContainer stop={stop} />;
};
