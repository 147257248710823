import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface StopsLeftProps {
  color?: string;
  style?: React.CSSProperties;
}

export const StopsLeft = ({ color = RoutalPalette.primary40, style = {}, ...props }: StopsLeftProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14" style={{ width: `12px`, height: `14px`, ...style }}>
      <g>
        <path
          fill={color}
          d="m8.86,5.26c0-1.58-1.28-2.86-2.86-2.86s-2.86,1.28-2.86,2.86,1.28,2.86,2.86,2.86,2.86-1.28,2.86-2.86Zm-4.66,0c0-.99.81-1.8,1.8-1.8s1.8.81,1.8,1.8-.81,1.8-1.8,1.8-1.8-.81-1.8-1.8Z"
        />
        <path
          fill={color}
          d="m8.87,9.89c1.07-1.16,2.06-2.76,2.03-4.7-.03-2.34-1.79-4.39-4.09-4.76-1.44-.23-2.89.17-3.99,1.1-1.1.93-1.72,2.29-1.72,3.73,0,1.92.96,3.49,2.01,4.63-1.54.27-2.9.8-2.9,1.77,0,1.59,3.63,2.01,5.78,2.01s5.78-.42,5.78-2.01c0-.97-1.37-1.51-2.92-1.78ZM3.51,2.34c.71-.6,1.57-.92,2.48-.92.22,0,.43.02.65.05,1.8.29,3.17,1.89,3.2,3.73.04,3.01-2.94,5.15-3.85,5.73-.9-.56-3.84-2.65-3.84-5.67,0-1.13.49-2.2,1.35-2.93Zm2.49,10.34c-3.12,0-4.69-.73-4.78-1,.07-.22,1-.69,2.8-.91.7.6,1.32,1,1.57,1.16.13.08.26.11.4.11s.28-.04.41-.12c.26-.16.87-.56,1.55-1.15,1.78.21,2.71.67,2.82.9-.14.3-1.7,1.01-4.78,1.01Z"
        />
      </g>
    </SvgIcon>
  );
};
