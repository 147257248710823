import { CustomersStop } from '../../domain/customersStop/CustomersStop';

export enum StopActionType {
  FETCH_STOP = `FETCH_STOP`,
  FETCH_STOP_FAILURE = `FETCH_STOP_FAILURE`,
  LOADING_STOP = `LOADING_STOP`,
}

export interface FetchStopAction {
  type: StopActionType.FETCH_STOP;
  payload: CustomersStop;
}

export interface FetchStopFailureAction {
  type: StopActionType.FETCH_STOP_FAILURE;
}

export interface LoadingStopAction {
  type: StopActionType.LOADING_STOP;
}

export type StopActions = FetchStopAction | FetchStopFailureAction | LoadingStopAction;
