import { Grid } from '@mui/material';
import { OSMap, OSMarker, RoutalPalette } from '@nx-smartmonkey/ui';
import { useEffect } from 'react';
import styled from 'styled-components';

import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { useIsExampleURL } from '../hooks/useIsExampleURL';

const MapContainer = styled(Grid)`
  min-height: 240px;
  width: 100vw;
`;

type CustomersMapProps = {
  stop?: CustomersStop;
};

export const CustomersMap = ({ stop }: CustomersMapProps) => {
  const { data: project } = useRetrieveProject();
  const { isExampleURL } = useIsExampleURL();

  const isMapEnabled =
    (stop?.status === `pending` &&
      (stop?.isFieldEnabled(`stop_position`) || stop?.isFieldEnabled(`driver_position`))) ||
    (stop?.status !== `pending` && stop?.isStopCompletedFieldEnabled(`location`));

  useEffect(() => {
    if (isMapEnabled) {
      // Removes the Leaf Let bottom text.
      const elements = document.querySelectorAll(`[class="leaflet-bottom leaflet-right"]`);
      (elements ?? []).forEach((el) => {
        (el as any).style.visibility = `hidden`;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isMapEnabled) return null;

  const color = project?.style?.primary_color?.value || RoutalPalette.primary40;

  return (
    <MapContainer item>
      <OSMap height="100%" dragging touchZoom={!isExampleURL} scrollWheelZoom={!isExampleURL}>
        {stop?.last_position?.getValue() && (
          <OSMarker
            key="route-position"
            lat={stop!.last_position!.lat!}
            lng={stop!.last_position!.lng!}
            kind="routal_truck"
            avatarUrl={stop.driver_info?.avatar}
            color={color}
          />
        )}
        {stop?.location?.isValid() && (
          <OSMarker key="service-position" lat={stop!.location!.lat!} lng={stop!.location!.lng!} color={color} />
        )}
        {stop?.report_location?.isValid() && (
          <OSMarker
            key="report-position"
            lat={stop!.report_location!.lat!}
            lng={stop!.report_location!.lng!}
            kind="report_position"
            color={color}
          />
        )}
      </OSMap>
    </MapContainer>
  );
};
