import { Grid } from '@mui/material';
import { HSpacer, RoutalPalette } from '@nx-smartmonkey/ui';

import * as routing from '../Routing';
import { Logo } from '../assets/svg/Logo';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { Text } from '../react-components/Text';

export const FOOTER_HEIGHT = 40;

export const CustomersFooter = () => {
  const { data: project, isLoading, success, error } = useRetrieveProject();

  if (isLoading || (!success && !error) || !project?.style?.show_powered_by) {
    return null;
  }

  return (
    <footer
      style={{
        display: `flex`,
        padding: `12px 25px`,
        backgroundColor: RoutalPalette.neutral00,
        height: `${FOOTER_HEIGHT}px`,
        width: `calc(100% - 50px)`,
        alignItems: `center`,
        justifyContent: `center`,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          width: `unset`,
          cursor: `pointer`,
        }}
        onClick={() => window.open(routing.EXTERNAL.LANDING)}
      >
        <Logo style={{ height: `20px`, width: `auto` }} />
        <HSpacer small />
        <Text variant="button" style={{ fontSize: `12px` }}>
          Powered by Routal®
        </Text>
      </Grid>
    </footer>
  );
};
