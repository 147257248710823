import { SvgIcon } from '@mui/material';
import React from 'react';

interface CanceledProps {
  style?: React.CSSProperties;
}

export const Canceled = ({ style = {}, ...props }: CanceledProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" style={{ width: `12px`, height: `12px`, ...style }}>
      <g>
        <path
          fill="white"
          d="m6.67,5.97L11.43,1.21c.2-.2.2-.51,0-.71s-.51-.2-.71,0l-4.76,4.76L1.21.51c-.2-.2-.51-.2-.71,0s-.2.51,0,.71l4.76,4.76L.5,10.73c-.2.2-.2.51,0,.71.1.1.23.15.35.15s.26-.05.35-.15l4.76-4.76,4.76,4.76c.1.1.23.15.35.15s.26-.05.35-.15c.2-.2.2-.51,0-.71l-4.76-4.76Z"
        />
      </g>
    </SvgIcon>
  );
};
