import { CustomersProject } from '../domain/customersProject/CustomersProject';
import { projectRepository } from '../repositories/project';
import { ProjectRepository } from '../repositories/project/ProjectRepository';

class ProjectService {
  constructor(private projectRepository: ProjectRepository) {}

  findByHost(host: string): Promise<CustomersProject> {
    return this.projectRepository.findByHost(host);
  }
}

export const projectService = new ProjectService(projectRepository);
