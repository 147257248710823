import { SvgIcon } from '@mui/material';
import React from 'react';

interface OnRouteProps {
  style?: React.CSSProperties;
}

export const OnRoute = ({ style = {}, ...props }: OnRouteProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12" style={{ width: `12px`, height: `12px`, ...style }}>
      <g>
        <path
          fill="white"
          d="m6,4.06c-1.08,0-1.95.87-1.95,1.95s.87,1.95,1.95,1.95,1.95-.87,1.95-1.95-.87-1.95-1.95-1.95Zm0,2.9c-.52,0-.95-.43-.95-.95s.43-.95.95-.95.95.43.95.95-.43.95-.95.95Z"
        />
        <path
          fill="white"
          d="m10.54,1.68l-.02-.02c-.15-.23-.45-.29-.68-.14-.23.15-.29.47-.14.7,1.47,2.3,1.47,5.27-.01,7.56-.15.23-.08.54.15.69.08.05.18.08.27.08.16,0,.32-.08.42-.23,1.7-2.62,1.7-6.02.02-8.65Z"
        />
        <path
          fill="white"
          d="m2.3,2.34c.15-.23.09-.55-.14-.7-.23-.15-.53-.1-.68.14l-.02.02c-1.69,2.63-1.68,6.02.02,8.65.1.15.26.23.42.23.09,0,.19-.03.27-.08.23-.15.3-.46.15-.69-1.48-2.29-1.49-5.26-.01-7.56Z"
        />
        <path
          fill="white"
          d="m3.9,3.34c.15-.23.07-.53-.16-.68-.23-.15-.55-.07-.69.16-1.24,1.94-1.24,4.44.01,6.37.1.15.26.23.42.23.09,0,.19-.03.27-.08.23-.15.3-.46.15-.69-1.04-1.6-1.04-3.68,0-5.29v-.02Z"
        />
        <path
          fill="white"
          d="m8.96,2.82c-.15-.23-.46-.31-.69-.16-.23.15-.31.45-.16.68v.02c1.04,1.61,1.04,3.68,0,5.29-.15.23-.08.54.15.69.08.05.18.08.27.08.16,0,.32-.08.42-.23,1.25-1.93,1.25-4.43.01-6.37Z"
        />
      </g>
    </SvgIcon>
  );
};
