import { Grid } from '@mui/material';

import { CustomersFooter } from './CustomersFooter';
import { CustomersHeader, HEADER_HEIGHT } from './CustomersHeader';

interface CustomersLayoutProps {
  bodyStyles?: React.CSSProperties;
}

export const CustomersLayout = ({ bodyStyles, children }: React.PropsWithChildren<CustomersLayoutProps>) => {
  return (
    <>
      <CustomersHeader />
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{
          position: `absolute`,
          top: `${HEADER_HEIGHT}px`,
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          overflow: `auto`,
          ...(bodyStyles ?? {}),
        }}
      >
        {children}
        <CustomersFooter />
      </Grid>
    </>
  );
};
