import { Survey } from '../../domain/survey/Survey';

export enum SurveyActionType {
  CREATE_SURVEY = `CREATE_SURVEY`,
  UPDATE_SURVEY = `UPDATE_SURVEY`,
}

export interface CreateSurveyAction {
  type: SurveyActionType.CREATE_SURVEY;
  payload: Survey;
}

export interface UpdateSurveyAction {
  type: SurveyActionType.UPDATE_SURVEY;
  payload: Survey;
}

export type SurveyActions = CreateSurveyAction | UpdateSurveyAction;
